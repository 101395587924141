import React from "react"
import {
  TiHomeOutline,
  TiSocialSkypeOutline,
  TiSocialLinkedin,
  TiSocialFacebook,
} from "react-icons/ti"
import { FiSettings, FiUser, FiMail } from "react-icons/fi"
import { IoIosCode, IoIosReturnRight } from "react-icons/io"
import {
  FaTelegramPlane,
  FaBitcoin,
  FaStackOverflow,
  FaGithub,
} from "react-icons/fa"

import { Link } from "gatsby"
import "./sidebar.css"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import Toast from "../ToastNotifications/ToastNotifications.js"

const Sidebar = props => (
  <div className="sidebar">
    <div>
      <div className="site_title">
        <AniLink fade to="/">
          <h4>
            {`  develop
            with
            tom.com
            `}
          </h4>
        </AniLink>
      </div>
      <div className="menu_list">
        <ul>
          <li>
            <div className="sidebar_button">
              <AniLink fade to="/" activeClassName="user-link active">
                <i>
                  <TiHomeOutline size={24} className="user-icon" />
                </i>
                <p className="user-text">HOME</p>
              </AniLink>
            </div>
          </li>
          <li>
            <div className="sidebar_button">
              <AniLink fade to="/about" activeClassName="user-link active">
                <i>
                  <FiUser size={24} className="user-icon" />
                </i>
                <p className="user-text">ABOUT</p>
              </AniLink>
            </div>
          </li>
          <li>
            <div className="sidebar_button">
              <AniLink fade to="/skills" activeClassName="user-link active">
                <i>
                  <FiSettings size={24} className="user-icon" />
                </i>
                <p className="user-text">SKILLS</p>
              </AniLink>
            </div>
          </li>

          <li>
            <div className="sidebar_button">
              <AniLink fade to="/work" activeClassName="user-link active">
                <i>
                  <IoIosCode size={24} className="user-icon" />
                </i>
                <p className="user-text">WORK</p>
              </AniLink>
            </div>
          </li>
          <li>
            <div className="sidebar_button">
              <AniLink fade to="/contact" activeClassName="user-link active">
                <i>
                  <FiMail size={24} className="user-icon" />
                </i>
                <p className="user-text">CONTACT</p>
              </AniLink>
            </div>
          </li>
        </ul>
      </div>
      <div className="social_list">
        <ul>
          <li className="telegram-icon">
            <a href="https://t.me/tgxxl">
              <i>
                <FaTelegramPlane size={18} />
              </i>
            </a>
          </li>
          <li className="github-icon">
            <a href="https://github.com/DevelopwithTom">
              <i>
                <FaGithub size={18} />
              </i>
            </a>
          </li>

          <li className="stackoverflow-icon">
            <a href="https://stackoverflow.com/users/8710665/tom-mac?tab=profile">
              <i>
                <FaStackOverflow size={18} />
              </i>
            </a>
          </li>

          <li className="bitcoin-icon">
            <i>
              <Toast
                message={
                  "Bitcoin accepted here. I enjoy working on small blockchain projects too."
                }
                icon={<FaBitcoin size={18} />}
              />
              {/* <FaBitcoin size={18} /> */}
            </i>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default Sidebar
