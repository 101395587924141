import React, { Component } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide, Zoom, Flip, Bounce } from "react-toastify"

import "./ToastNotifications.css"
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

class Toast extends Component {
  //   notify = () => toast(this.props.message)

  notify = () => {
    // toast(this.props.message)

    toast(this.props.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      progressClassName: "fancy-progress-bar",
      className: "toast_main_body",
    })
  }

  render() {
    return (
      <div>
        <i onClick={this.notify}>{this.props.icon}</i>
        <ToastContainer
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          transition={Zoom}
        />
      </div>
    )
  }
}

export default Toast
